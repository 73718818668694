exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-cart-order-jsx": () => import("./../../../src/pages/cart/order.jsx" /* webpackChunkName: "component---src-pages-cart-order-jsx" */),
  "component---src-pages-crimea-jsx": () => import("./../../../src/pages/crimea.jsx" /* webpackChunkName: "component---src-pages-crimea-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-moscow-jsx": () => import("./../../../src/pages/moscow.jsx" /* webpackChunkName: "component---src-pages-moscow-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-news-more-jsx": () => import("./../../../src/pages/newsMore.jsx" /* webpackChunkName: "component---src-pages-news-more-jsx" */),
  "component---src-pages-photo-jsx": () => import("./../../../src/pages/photo.jsx" /* webpackChunkName: "component---src-pages-photo-jsx" */),
  "component---src-pages-photo-sheet-jsx": () => import("./../../../src/pages/photoSheet.jsx" /* webpackChunkName: "component---src-pages-photo-sheet-jsx" */),
  "component---src-pages-schedule-crimea-jsx": () => import("./../../../src/pages/schedule/crimea.jsx" /* webpackChunkName: "component---src-pages-schedule-crimea-jsx" */),
  "component---src-pages-schedule-disabled-sochi-jsx": () => import("./../../../src/pages/schedule/disabled-sochi.jsx" /* webpackChunkName: "component---src-pages-schedule-disabled-sochi-jsx" */),
  "component---src-pages-schedule-moscow-jsx": () => import("./../../../src/pages/schedule/moscow.jsx" /* webpackChunkName: "component---src-pages-schedule-moscow-jsx" */),
  "component---src-pages-shop-accessories-index-jsx": () => import("./../../../src/pages/shop/accessories/index.jsx" /* webpackChunkName: "component---src-pages-shop-accessories-index-jsx" */),
  "component---src-pages-shop-clothes-index-jsx": () => import("./../../../src/pages/shop/clothes/index.jsx" /* webpackChunkName: "component---src-pages-shop-clothes-index-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-shop-product-jsx": () => import("./../../../src/pages/shop/product.jsx" /* webpackChunkName: "component---src-pages-shop-product-jsx" */),
  "component---src-pages-sochi-jsx": () => import("./../../../src/pages/sochi.jsx" /* webpackChunkName: "component---src-pages-sochi-jsx" */)
}

